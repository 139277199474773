import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    singleIconContainter: {
        maxWidth: '10.26vw',
        minWidth: '10.26vw',
        marginTop: '5.26vh',
        '@media (max-width: 768px)': {
            maxWidth: '34.06vw',
            minWidth: '34.06vw',
        },
    },
    cameraIcon: {
        width: '7.81vw',
        height: 100,
        backgroundImage: props => `url(${props.src})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        margin: '0 auto',
        '@media (max-width: 768px)': {
            maxWidth: '24.06vw',
            minWidth: '24.06vw',
        },
    },
    iconTitle: {
        fontSize: '14px',
        textAlign: 'center',
        margin: 0,
        marginTop: '4.44vh',
        '@media (max-width: 768px)': {
            marginTop: '5.3vw',
        },
    },
}));

import React, { useState, useEffect, useRef } from 'react';
import { useStyles } from '../styles/smart-city.styles';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { handleObserver } from '../utils/observer';
import SmartIcon from '../components/SmartIcon/SmartIcon';

import {
    cameraIcon,
    carIcon,
    socialIcon,
    wifiIcon,
    cloudIcon,
    dataIcon,
    lampIcon,
    windIcon,
} from '../images/icons/iconsExport';
import {
    katowiceExperienceEN,
    katowiceExperiencePL,
    dropInCrimeEN,
    dropInCrimePL,
    smartCityAboutTextOnePartOneEN,
    smartCityAboutTextOnePartOnePL,
    smartCityAboutTextOnePartTwoPL,
    smartCityAboutTextOnePartTwoEN,
    smartCityAboutTextTwoPartOneEN,
    smartCityAboutTextTwoPartOnePL,
    smartCityAboutTextTwoPartTwoPL,
    smartCityAboutTextTwoPartTwoEN,
} from '../utils/static';

const SmartCity = ({ isEnglish }) => {
    const classes = useStyles();
    const [isVisible, setVisible] = useState(false);

    const refSmart = useRef(null);

    useEffect(() => {
        const currentRef = refSmart.current;
        handleObserver(currentRef, setVisible);
    }, []);
    const dropInCrimeText = isEnglish ? dropInCrimeEN : dropInCrimePL;
    const experienceText = isEnglish
        ? katowiceExperienceEN
        : katowiceExperiencePL;
    const smartCityAboutTextOnePartOne = isEnglish
        ? smartCityAboutTextOnePartOneEN
        : smartCityAboutTextOnePartOnePL;
    const smartCityAboutTextOnePartTwo = isEnglish
        ? smartCityAboutTextOnePartTwoEN
        : smartCityAboutTextOnePartTwoPL;
    const smartCityAboutTextTwoPartOne = isEnglish
        ? smartCityAboutTextTwoPartOneEN
        : smartCityAboutTextTwoPartOnePL;
    const smartCityAboutTextTwoPartTwo = isEnglish
        ? smartCityAboutTextTwoPartTwoEN
        : smartCityAboutTextTwoPartTwoPL;

    return (
        <Layout backgroundColor="#140091">
            <SEO title="Smart City" />
            <div className={classes.root} id="smart-city">
                <div ref={refSmart}>
                    <div className={classes.wrapperIdea}>
                        <div
                            className={
                                isVisible
                                    ? classes.columnOne
                                    : classes.columnOneHidden
                            }
                        >
                            <div className={classes.infoWrapper}>
                                <div className={classes.headerWrapper}>
                                    <h2 className={classes.infoHeader}>
                                        IDEA SMART CITY
                                    </h2>
                                </div>
                                <p className={classes.infoText}>
                                    {smartCityAboutTextOnePartOne}
                                </p>
                                <p className={classes.infoText}>
                                    {smartCityAboutTextOnePartTwo}
                                </p>
                            </div>
                            <div className={classes.infoWrapper}>
                                <div className={classes.headerWrapper}>
                                    <h2 className={classes.infoHeader}>
                                        {dropInCrimeText}
                                    </h2>
                                    <p className={classes.infoSubHeader}>
                                        {experienceText}
                                    </p>
                                </div>
                                <p className={classes.infoText}>
                                    {smartCityAboutTextTwoPartOne}
                                </p>
                                <p className={classes.infoText}>
                                    {smartCityAboutTextTwoPartTwo}
                                </p>
                            </div>
                        </div>
                        <div
                            className={
                                isVisible
                                    ? classes.columnTwo
                                    : classes.columnTwoHidden
                            }
                        >
                            <div className={classes.iconsContainer}>
                                <SmartIcon
                                    src={cameraIcon}
                                    iconTitle={
                                        isEnglish
                                            ? 'City monitoring'
                                            : 'Monitoring miejski'
                                    }
                                />
                                <SmartIcon
                                    src={carIcon}
                                    iconTitle={
                                        isEnglish
                                            ? 'Traffic management'
                                            : 'Zarządzanie ruchem drogowym'
                                    }
                                />
                                <SmartIcon
                                    src={socialIcon}
                                    iconTitle={
                                        isEnglish
                                            ? 'Social integration'
                                            : 'Integracja społeczna'
                                    }
                                />
                                <SmartIcon src={wifiIcon} iconTitle="Wi-fi" />
                                <SmartIcon
                                    src={cloudIcon}
                                    iconTitle="Big Data"
                                />
                                <SmartIcon
                                    src={dataIcon}
                                    iconTitle={
                                        isEnglish
                                            ? 'Data analysis'
                                            : 'Analiza danych'
                                    }
                                />
                                <SmartIcon src={lampIcon} iconTitle="IoT" />
                                <SmartIcon
                                    src={windIcon}
                                    iconTitle={
                                        isEnglish
                                            ? 'Air quality'
                                            : 'Jakość powietrza'
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default SmartCity;

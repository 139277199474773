import React from 'react';
import { useStyles } from './SmartIcon.styles';

const SmartIcon = props => {
    const classes = useStyles(props);
    const { iconTitle } = props;
    return (
        <div className={classes.singleIconContainter}>
            <div className={classes.cameraIcon}></div>
            <p className={classes.iconTitle}>{iconTitle}</p>
        </div>
    );
};

export default SmartIcon;

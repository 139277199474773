import { makeStyles } from '@material-ui/core/styles';
import CameraIcon from '../images/icons/cameraIcon.svg';

export const useStyles = makeStyles(theme => ({
    '@keyframes slidein-left': {
        '0%': {
            transform: 'translateX(-7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    '@keyframes slidein-right': {
        '0%': {
            transform: 'translateX(7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    root: {
        backgroundColor: theme.palette.blue,
        height: '100vh',
        width: '100vw',

        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    wrapper: {
        display: 'flex',
        height: '93vh',
        alignItems: 'center',
    },
    wrapperIdea: {
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            height: 'auto',
        },
    },
    columnOne: {
        maxWidth: '42vw',
        minWidth: '42vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: '2.4vh',
        animation: '$slidein-left 0.5s ease backwards',
        '@media (max-width: 768px)': {
            maxWidth: '75vw',
            minWidth: '75vw',
            animation: 'none',
            alignItems: 'center',
        },
    },
    columnTwo: {
        maxWidth: '58vw',
        minWidth: '58vw',
        paddingLeft: '5.2vw',
        animation: '$slidein-right 0.5s ease backwards',
        '@media (max-width: 768px)': {
            marginTop: '2.4vh',
            maxWidth: '75vw',
            minWidth: '75vw',
            animation: 'none',
            paddingLeft: 0,
        },
    },
    columnOneHidden: {
        maxWidth: '42vw',
        minWidth: '42vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: '2.4vh',
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
        '@media (max-width: 768px)': {
            maxWidth: '75vw',
            minWidth: '75vw',
            animation: 'unset',
            alignItems: 'center',
        },
    },
    columnTwoHidden: {
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        maxWidth: '58vw',
        minWidth: '58vw',
        paddingLeft: '5.2vw',
        '@media (max-width: 768px)': {
            opacity: 1,
            maxWidth: '75vw',
            minWidth: '75vw',
            animation: 'unset',
            paddingLeft: '0',
        },
    },
    iconsContainer: {
        maxWidth: '42.08vw',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '@media (max-width: 768px)': {
            maxWidth: '75vw',
            minWidth: '75vw',
        },
    },
    infoWrapper: {
        minWidth: '31.66vw',
        maxWidth: '35vw',
        marginTop: '4.8vh',
        '@media (max-width: 768px)': {
            maxWidth: '75vw',
            minWidth: '75vw',
        },
    },
    headerWrapper: {
        marginBottom: '3.5vh',
    },
    infoHeader: {
        fontSize: '3.7vh',
        lineHeight: '3.7vh',
        margin: 0,
    },
    infoSubHeader: {
        margin: 0,
        fontSize: '3.33vh',
        fontWeight: 100,
        lineHeight: '3.33vh',
    },
    infoText: {
        fontSize: '1vw',
        '@media (max-width: 768px)': {
            fontSize: 16,
            letterSpacing: 0.2,
        },
        '@media (max-width: 460px)': {
            fontSize: 12,
            letterSpacing: 0.2,
        },
    },
}));
